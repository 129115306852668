@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-nav {
  background-color: #191b1f;
}

/* .navbar-brand {
  display: flex;
  align-items: center;
} */
.navbar-brand > img {
  /* padding: 1px 1px; */
}
*/ .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0rem !important;
}

.text-center {
  font-family: "ubuntu", sans-serif;
  align-items: center;
}

.account-dropdown {
  height: 300px;
  overflow-y: scroll;
}
.navbar {
  padding: 0rem 0rem !important;
}

.site-dropdown-text {
  font-size: 1.1em;
}

.nav-item .dropdown-menu {
  background: #191b1f;
}

.nav-item .dropdown-item {
  color: #ffffff;
}

.nav-item .dropdown-item:hover {
  background: #f0ad4e;
}

.box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-between;
  margin: 0 -20px;
  grid-gap: 10px;
  gap: 10px;
}

.one-left {
  margin-left: 1px;
}

.centered {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.white-text {
  color: #eeeeee;
}

.red-text {
  color: rgb(255, 15, 15);
}

.grey-text {
  color: rgb(161, 160, 160);
}
.logoText {
  color: #eeeeee;
}

.green-text {
  color: #04a902;
}

.bigInput {
  color: #04a902 !important;
  background-color: black !important;
  outline-width: 0 !important;
  font-size: 18px;
  width: 80vw;
  border: 10px !important;
}

.invalid {
  color: #eeeeee !important;
  background-color: #ff0033 !important;
  outline-width: 0 !important;
}

.invalid-text {
  color: #ff0033;
}

.smallInput {
  color: #04a902;
  background-color: black;
  outline: black solid 1px;

  font-size: 18px;
  width: 50vw;
}

.verySmallInput {
  color: #04a902;
  background-color: black;
  outline-width: 0 !important;
  font-size: 18px;
  width: 96%;
  border-radius: 3px;
}

.search-input {
  color: #04a902;
  background-color: black;
  outline-width: 0 !important;
  font-size: 18px;
  width: 25%;
  border-radius: 3px;
  text-align: center;
  margin-left: 72%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.subjectInput {
  color: #04a902;
  background-color: black;
  outline-width: 0;
  font-size: 18px;
  width: 50vw;
}

.fixedWidth {
  box-sizing: padding-box;
  -moz-box-sizing: padding-box;
  -webkit-box-sizing: padding-box;
  padding-bottom: 60px;
}

#wide {
  flex: 2 1;
  /* box-sizing: padding-box; */
  /* Grow to rest of container */
  background: #131415;
  overflow: scroll;
  /* height: 100vh; */
}

.scrollable {
  overflow-y: scroll;
  height: 60vh;
}

.scrollable-full {
  overflow-y: scroll;
  height: 100vh;
}

.site-list {
  overflow-y: scroll;
  height: 80vh;
  background-color: #161616;
}

.all-site-list {
  height: 100vh;
  width: 99vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  margin-top: 15px;
  padding-top: 0.01vh;
  justify-content: center;
}

.container-fluid {
  overflow-y: scroll !important;
  height: calc(100% - 155px) !important;
}

.add-space {
  margin: 5px;
}

.message-text {
  width: 90%;
  height: 100%;
  text-align: left;
  margin-left: 5%;
  background: #131415;
}

.noteSection {
  display: flex;
  border-radius: 8px !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20em;
  height: 160px;
  background: #222222;
  border-radius: 2px;
  left: 20px;
  top: 10px;
  border: 0.1em solid #5b5e64;
  cursor: pointer;
}

.messageBox {
  display: flex;
  flex-direction: column;
  color: #eeeeee;
  font-family: "ubuntu", sans-serif;
  flex-wrap: wrap;
  min-height: 50%;
}
.focusedNote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20em;
  height: 160px;
  background: #535657b6;
  border-radius: 2px;
  left: 20px;
  top: 10px;
  margin-top: 10px;
  margin-left: 100px;
  border: 0.1em solid #5b5e64;
}

.noteSection:hover {
  background-color: #4187ce;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#parent {
  display: flex;
  height: 50em;
  border-top: 1px solid #eeeeee9f;

  /* overflow: hidden; */
}

.yc-cursor-text {
  --text-color: #00aa00;
  color: var(--text-color);
  font-size: 50px;
  letter-spacing: -5px;
  margin-bottom: 20px;
}

.green-text {
  color: #00aa00;
}

.bal-text {
  font-size: 1.3rem;
}

.pinata-warning {
  font-style: italic;
  font-size: 0.9rem;
}

.italic-text {
  font-style: italic;
}

.logo-img {
  width: 400px;
  height: 113px;
  margin-top: 5vh;
  margin-left: 4.5vw;
}

ul.a {
  list-style-type: circle;
}

.homepage-container {
  max-width: 600px;
  margin: auto;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Mono", sans-serif;

  color: #ccc;
  font-size: 18px;
  line-height: 1.6;
}

.app-link {
  color: #00aa00;
  text-decoration: none;
}

.home-ul {
  list-style-type: none;
}

.narrow {
  overflow: hidden;
  width: auto;
  max-width: 23%;
  margin: auto;
  height: 100vh;
  background: #191b1f;

  /* border-top: 2px solid #04A902;
  border-right: 3px solid #04A902; */
  /* Just so it's visible */
  border-right: 1px solid #eeeeee9f;
  box-sizing: padding-box;
  -moz-box-sizing: padding-box;
  -webkit-box-sizing: padding-box;
  padding-bottom: 60px;
}

.notes-list {
  height: 85%;
  overflow-y: scroll;
}

.notes-list-adjusted {
  height: 70.5%;
  overflow-y: scroll;
}

.account-dropdown {
  height: 300px;
  overflow-y: scroll;
}

.close-button {
  position: absolute;
  top: 1%;
  right: 1%;
}

.note-divider {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #eeeeee9f;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.site-divider {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #eeeeee9f;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#line {
  float: left;
  width: 731px;
  height: 40px;
}

div.settings {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
div.settings label {
  text-align: right;
}

li {
  list-style-type: none;
  align-self: center;
}

.accountListContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.back-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 5px;
  padding-bottom: 0px;
  /* width: 150px !important; */
}

.back-caret {
  margin-top: 0.3rem;
}

.header-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  height: 20%;
}

.subject-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2%;
  margin-top: 1%;
}

.sender-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 2%;
}

.timestamp-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.subject-info-container {
  margin-top: 1%;
  margin-bottom: 0%;
}

.message-actions {
  flex: 50% 1;
  text-align: right;
  margin-top: 1%;
  margin-left: 1.3%;
}

.message-actions-below {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5%;
  padding-bottom: 2.5%;
  justify-content: flex-start;
}

.message-body {
  font-size: 1.08em;
  white-space: pre-line;
}
.bold-text {
  font-weight: bold;
}

div.toLabel {
  display: inline-block;
  /*ie7 -- start*/
  *display: inline;
  zoom: 1;
}

scroll-flow {
  overflow-y: scroll;
}

div.toLabel > label {
  display: flex;
  flex-direction: row-reverse;
}

.ubuntu {
  font-family: "ubuntu", sans-serif;
}

.text-center {
  font-family: "ubuntu", sans-serif;
  text-align: center;
}

.notes-searchbar {
  width: 10%;
  margin-bottom: 10px;

  background-color: #eeeeee;
}

/* body.ReactModal__Body--open {
  overflow: hidden;
  position: relative;

  #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(2px);
  }
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
}
::-webkit-scrollbar {
  width: 2px; /* Remove scrollbar space */

  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #eeeeee;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-send {
  background-color: #97f463;
  color: #eeeeee;
}

.btn-flat {
  background-color: purple;
  color: white;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.no-messages {
  margin-top: 40vh;
  padding: 10px;
  font-size: 32px;
}

.sender-address {
  cursor: help;
  font-size: 14px;
}

.sender-address-notes {
  cursor: help;
  font-size: 16px;
}

.sender-address:hover {
  border-bottom-color: #eeeeee;
  text-decoration: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
}

#progress-container {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  color: #eeeeee;
  font-family: "ubuntu", sans-serif;
}

#progress {
  margin-left: 20vw;
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.site-delete-button {
  /* padding-top: 5%; */
  width: 190px;
  /* border: 1px solid red; */
}

.site-box {
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  border-left: 2px solid #222222;
  border-right: 2px solid #222222;
  background-color: #080808;
  width: 5px;
  padding: 12px;
}

.site-box-bigger {
  border-radius: 24px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  background-color: #222222;
  width: 10px;
  padding: 35px;
  padding-top: 15px;
  padding-bottom: 20px;
  word-wrap: break-word;
}

.site-box-detail {
  /* border-radius: 24px; */
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  background-color: #222222;

  /* padding-top: 15px;
  padding-bottom: 20px; */
  word-wrap: break-word;
}

.custom-btn {
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.topleftcorner {
  position: absolute;
  top: 3%;
  left: 3%;
}

.iframe-loader {
  position: absolute;
  top: 8%;
  left: 6%;
}

.detail-loader {
  position: absolute;
  top: 50%;
  left: 40%;
}

.toprightcorner {
  position: absolute;
  top: 8%;
  right: 3%;
}

.search-spot {
  position: relative;
  top: 18%;
  right: 3%;
  overflow-y: scroll;
  margin-left: 11%;
}

.note-button-corner {
  position: absolute;
  top: 11%;
  right: 1%;
}

.back-dark {
  border-top: 1px solid #333333 !important;
  border-bottom: 1px solid #333333 !important;
  border-left: 1px solid #333333 !important;
  border-right: 1px solid #333333 !important;
  background-color: #222222 !important;
  border-radius: 20px !important;
  box-shadow: 0 0 0 0.1rem #444444 !important;

  border-radius: 12px;
  padding-bottom: 1px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgreen;
  opacity: 0.4; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgreen;
  opacity: 0.4; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgreen;
  opacity: 0.4; /* Firefox */
}

.box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-between;
  margin: 0 -20px;
  grid-gap: 10px;
  gap: 10px;
  color: #eeeeee;
}

.box:hover {
  background: #f0ad4e !important;
}

.dropdown-menu {
  background: #191b1f !important;
  color: #eeeeee !important;
}

.dropdown-item:hover {
  background: #080808 !important;
}

.my-modal .modal-content {
  background-color: #191b1f;
}

.block-container {
  padding-top: 16px;
}

.block-num {
  margin-top: 0.99rem;
}

.slight-bottom-margin {
  padding-bottom: 0.2rem;
  margin-bottom: 0.04rem;
}

.block-flex {
  display: flex;
  flex-direction: row;
}
.curr-block-text {
  margin-top: 0.2rem;

  padding-top: 7px;
}

.fa-xs {
  font-size: 0.5em;
}

.detail-modal .modal-content {
  background-color: #191b1f;
  border-radius: 30px;
}

.align-left {
  justify-content: left;
}

.iframe-container {
  border-radius: 20px;
  width: 250px;
  overflow: hidden;
}

.wrap {
  width: 320px;
  height: 170px;
  padding: 0;
  overflow: hidden;
}

.frame {
  width: 1280px;
  height: 786px;
  border: 1;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.detail-frame {
  width: 98%;
  height: 50%;

  zoom: 2;
}

.cursor {
  font-weight: 700;
  -webkit-animation: 1s blink step-end infinite;
          animation: 1s blink step-end infinite;
}

.blink_text {
  animation: cursor-blink 1.5s steps(2) infinite;
  -webkit-animation: 1s blinker linear infinite;
  -moz-animation: 1s blinker linear infinite;
  font-weight: 700;
  color: #00aa00;
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.block-align {
  display: flex;
  flex-direction: row;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #04a902 !important;
}

input:-internal-autofill-previewed {
  -webkit-text-fill-color: #04a902 !important;
}

input#recip-input {
  font-size: 1rem;
}

input:-webkit-autofill::first-line {
  font-size: 18px;
}

input {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
}

.centered {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  color: #eeeeee;
}

.white-text {
  color: #eeeeee;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.nav-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* Align Items Vertically */
  /* justify-content: space-between; */
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.App-header {
  background-color: #333333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body {
  font-family: "PT Sans", sans-serif;
}

