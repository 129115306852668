input {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.centered {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  color: #eeeeee;
}

.white-text {
  color: #eeeeee;
}
